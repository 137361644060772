import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const mapData = {
  center: [60.757024, 72.791917],
  zoom: 16,
};

const coordinates = [
  [60.757024, 72.791917],
  // [57.684758, 39.738521],
];

const MyPlacemark = () => (
  <YMaps>
    <Map defaultState={mapData}>
      {coordinates.map((coordinate) => (
        <Placemark key={coordinate[0]} geometry={coordinate} />
      ))}
    </Map>
  </YMaps>
);

export default MyPlacemark;
