import React from 'react';

import MyPlacemark from '../components/Ymap.js';
import Layout from '../components/layout';
import SEO from '../components/seo';

// import menuItems from '../json/menu';
// import dogIllustration from '../images/dog-illustration.svg';

function AboutPage() {
    return (
        <Layout>
            <SEO
                keywords={[
                    `ЧОУ Профф Пыть-Ях`,
                    `образовательное учреждение`,
                    `профессиональное обучение`,
                ]}
                title="About"
            />

            <section className="flex flex-col items-center ">
                <div className="">
                    <h1 className="text-2xl text-gray-800 mb-4 font-bold text-center uppercase tracking-wider">
                        Основные сведения об образовательной организации
                    </h1>
                    <div className="p-4 mb-4 bg-gray-100 rounded shadow">
                        <h2 className="text-green-600 text-2xl mb-4">
                            Полное наименование организации:
                        </h2>
                        <p className="text-lg">
                            Частное образовательное учреждение дополнительного
                            профессионального образования «Профф»
                        </p>
                    </div>
                    <div className="p-4 mb-4 bg-gray-100 rounded shadow">
                        <h2 className="text-green-600 text-2xl mb-4">
                            Сокращенное наименование организации:
                        </h2>
                        <p className="text-lg">ЧОУ ДПО «Профф»</p>
                    </div>
                    <div className="p-4 mb-4 bg-gray-100 rounded shadow">
                        <h2 className="text-green-600 text-2xl mb-4">
                            Дата создания:
                        </h2>
                        <p className="text-lg">12 февраля 2013 года</p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded shadow">
                        <h2 className="text-green-600 text-2xl mb-4">
                            Учредитель образовательной организации:
                        </h2>
                        <p className="text-lg mb-4 ">
                            Протопопова Елена Сергеевна
                        </p>
                    </div>
                    <div className="p-4 bg-gray-100 rounded shadow mt-4">
                        <h2 className="text-green-600 text-2xl mb-4">
                            Место осуществления образовательной деятельности:
                        </h2>
                        <p className="text-lg mb-4 ">
                            628383, Ханты-Мансийский Автономный округ - Югра АО,
                            Пыть-Ях г, 6 Пионерный мкр, дом № 30
                        </p>
                    </div>
                    <div className="mt-4 flex">
                        <div className="mt-4 w-1/2">
                            <MyPlacemark />
                        </div>
                        <div className="py-4">
                            <p>
                                628383, Ханты-Мансийский Автономный округ - Югра
                                АО, Пыть-Ях г, 6 Пионерный мкр, дом № 30
                            </p>
                            <div className="bg-white rounded p-2 my-4">
                                <h3 className="font-bold">
                                    Режим и график работы:
                                </h3>
                                <ul>
                                    <li>
                                        понедельник - пятница: с 09:00 до 20:00
                                    </li>
                                    <li>суббота: с 09:00 до 16:00</li>
                                    <li>воскресенье: выходной</li>
                                </ul>
                            </div>

                            <p className="mb-1">
                                {' '}
                                <span className="font-bold">
                                    Контактный телефон:
                                </span>{' '}
                                +7 3463 461 444
                            </p>
                            <p className="mb-1">
                                <span className="font-bold">
                                    Телефон для SMS сообщений
                                </span>{' '}
                                +7 9322575263
                            </p>
                            <p>
                                <span className="font-bold">
                                    Адрес электронной почты:
                                </span>{' '}
                                proff461444@mail.ru
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default AboutPage;
